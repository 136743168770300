
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, LuxmeterDetails, LuxmeterItem } from "@/domain";

import { ILuxmeterService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class LuxmetersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get luxmeterService() {
    return this.container.resolve<ILuxmeterService>(S.LUXMETER_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.luxmeters, (l) => l.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/LuxmeterCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/LuxmeterUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  luxmeters: LuxmeterItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.luxmeters = await this.luxmeterService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(luxmeter: LuxmeterDetails) {
    this.luxmeters.push(LuxmeterItem.fromDetails(luxmeter));
  }

  update(luxmeter: LuxmeterDetails) {
    const index = _.findIndex(this.luxmeters, (l) => l.id == luxmeter.id);
    if (index != -1) {
      this.luxmeters.splice(index, 1, LuxmeterItem.fromDetails(luxmeter));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const luxmeter = _.find(this.luxmeters, (l) => l.id == id)!;
      const result = await this.luxmeterService.update(id, {
        serialNumber: luxmeter.serialNumber,
        equipmentType: luxmeter.equipmentType,
        equipmentBrandId: luxmeter.equipmentBrand.id,
        pvNumber: luxmeter.pvNumber,
        disabled: !luxmeter.disabled,
      });

      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
